/* eslint-disable import/no-anonymous-default-export */
import { JOBLISTING } from "../actions";

const initialState = {
  jobs: null,
  locations: [],
  disabledBtn: false,
  requestCount: 0,
  allJobsData: [],
  getAllJobsLoading: false,
  gettingTaskIdLoading: false,
  taskId: "",
  getTaskIdFlag: false,
  getTaskLoading: false,
  getJobsStatus: "pending",
  filterValue: "",
  startDateVal: "",
  endtDateVal: "",
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case JOBLISTING.SET_PROPS:
      return { ...state, ...payload };
    case JOBLISTING.GET_JOBS_LISTING:
      const { result, offset } = payload;
      return {
        ...state,
        jobs: result.data.length === 0 && offset > 0 ? state.jobs : result.data,
        disabledBtn: offset > 0 && result.data.length === 0 ? true : false,
        getJobsStatus: "pending",
        allJobsData: [],
        getAllJobsLoading: false,
        getTaskLoading: false,
      };
    case JOBLISTING.GET_TASK_ID:
      return {
        ...state,
        gettingTaskIdLoading: true,
      };
    case JOBLISTING.GET_TASK_ID_SUCCESS:
      return {
        ...state,
        taskId: payload.data.result.task_id,
        gettingTaskIdLoading: false,
        getTaskIdFlag: true,
        requestCount: 1,
      };
    case JOBLISTING.GET_TASK_ID_FAILURE:
      return {
        ...state,
        taskId: {},
        gettingTaskIdLoading: false,
        getAllJobsLoading: false,
      };
    case JOBLISTING.GET_ALL_JOBS_DATA:
      return {
        ...state,
        getAllJobsLoading: true,
      };
    case JOBLISTING.GET_ALL_JOBS_DATA_SUCCESS:
      console.log("data status", payload.task_status);
      var arrayOfObject =
        payload.task_status === "SUCCESS" &&
        payload.result.length > 0 &&
        payload.result.map(function (row) {
          return {
            title: row[0],
            job_link: row[1],
            company: row[2],
            company_link: row[3],
            location: row[4],
            created_date: row[5],
            latest_date: row[6],
            source: row[7],
          };
        });
      if (arrayOfObject.length > 0) {
        arrayOfObject.shift();
      }
      return {
        ...state,
        allJobsData:
          payload.task_status === "SUCCESS" ? arrayOfObject : state.allJobsData,
        getTaskIdFlag: payload.task_status === "SUCCESS" ? false : true,
        requestCount:
          payload.task_status === "SUCCESS" && state.requestCount != 1
            ? 0
            : state.requestCount + 1,
        getJobsStatus: payload.task_status,
        getAllJobsLoading: payload.task_status === "SUCCESS" ? false : true,
      };

    case JOBLISTING.GET_ALL_JOBS_DATA_FAILURE:
      return {
        ...state,
        getAllJobsLoading: false,
      };
    case JOBLISTING.GET_LOCATIONS:
      return {
        ...state,
        locations: payload.countries,
        filterValue: "",
        startDateVal: "",
        endtDateVal: "",
      };
    case JOBLISTING.SET_FILTER_VALUE:
      return {
        ...state,
        filterValue: payload.value,
        startDateVal: "",
        endtDateVal: "",
      };
    case JOBLISTING.SET_START_DATE_VALUE:
      return {
        ...state,
        startDateVal: payload.startDate,
        filterValue: "",
        endtDateVal: "",
      };
    case JOBLISTING.SET_END_DATE_VALUE:
      return {
        ...state,
        endtDateVal: payload.endDate,
        filterValue: "",
      };
    case JOBLISTING.RESET_FILTER_VALUE:
      return {
        ...state,
        filterValue: "",
        startDateVal: "",
        endtDateVal: "",
      };

    default:
      return {
        ...state,
      };
  }
};
