import axios from "axios";
import { toast } from "react-toastify";

import { JOBLISTING, SERVER_ERROR } from "./types";
import setAuthToken from "../../utils/setAuthToken";
//Get header information

export const getJobsListing = (values, history) => async (disptach) => {
  try {
    disptach({
      type: JOBLISTING.SET_PROPS,
      payload: { gettingJobsListing: true },
    });
    setAuthToken();
    const response = await axios.get(
      values.start_date && values.end_date
        ? `/api/lg/jobs/?location=${values.location}&tags=${values.tags}&end_date=${values.end_date}&start_date=${values.start_date}&page=${values.page}`
        : `/api/lg/jobs/?location=${values.location}&tags=${values.tags}&date_limit=${values.date_limit}&offset=${values.page}`
    );
    disptach({
      type: JOBLISTING.SET_PROPS,
      payload: { gettingJobsListing: false },
    });
    disptach({
      type: JOBLISTING.GET_JOBS_LISTING,
      payload: {
        result: response.data,
        offset: values.page,
      },
    });
  } catch (error) {
    console.log("jobs error", error);
    if (error && error.response && error.response.status === 401) {
      history.push("/app/auth");
      localStorage.removeItem("refresh");
      localStorage.removeItem("userToken");
    }
    disptach({
      type: JOBLISTING.SET_PROPS,
      payload: { gettingJobsListing: false },
    });

    if (!error.response) {
      toast.error(error.message || SERVER_ERROR);
      return;
    }

    const { data } = error.response;
    const message = data && data.message ? data.message : error.message;
    toast.error(message);
  }
};

export const getTaskId = (values, history) => async (disptach) => {
  try {
    disptach({
      type: JOBLISTING.GET_TASK_ID,
      payload: {},
    });
    setAuthToken();
    const response = await axios.get(
      values.start_date && values.end_date
        ? `/api/lg/csv-file/?location=${values.location}&tags=${values.tags}&end_date=${values.end_date}&start_date=${values.start_date}&page=${values.page}`
        : `/api/lg/csv-file/?location=${values.location}&tags=${values.tags}&date_limit=${values.date_limit}&offset=${values.page}`
    );

    disptach({
      type: JOBLISTING.GET_TASK_ID_SUCCESS,
      payload: response,
    });
  } catch (error) {
    console.log("jobs error", error);
    if (error.response.status === 401) {
      history.push("/app/auth");
      localStorage.removeItem("refresh");
      localStorage.removeItem("userToken");
    }
    disptach({
      type: JOBLISTING.GET_TASK_ID_FAILURE,
      payload: {},
    });

    if (!error.response) {
      toast.error(error.message || SERVER_ERROR);
      return;
    }

    const { data } = error.response;
    const message = data && data.message ? data.message : error.message;
    toast.error(message);
  }
};
export const getAllJobsData = (taskId, history) => async (disptach) => {
  try {
    disptach({
      type: JOBLISTING.GET_ALL_JOBS_DATA,
      payload: {},
    });
    setAuthToken();

    const response = await axios.get(
      `https://webdata-207211.appspot.com/api/lg/csv-file/?task_id=${taskId}`
    );
    disptach({
      type: JOBLISTING.GET_ALL_JOBS_DATA_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    console.log("jobs error", error);
    if (error.response.status === 401) {
      history.push("/app/auth");
      localStorage.removeItem("refresh");
      localStorage.removeItem("userToken");
    }
    disptach({
      type: JOBLISTING.GET_ALL_JOBS_DATA_FAILURE,
      payload: {},
    });

    if (!error.response) {
      toast.error(error.message || SERVER_ERROR);
      return;
    }

    const { data } = error.response;
    const message = data && data.message ? data.message : error.message;
    toast.error(message);
  }
};

//Get Locations
export const getLocations = (history) => async (disptach) => {
  try {
    disptach({
      type: JOBLISTING.SET_PROPS,
      payload: { gettingLocations: true },
    });
    setAuthToken();
    const response = await axios.get(`/api/lg/locations/`);
    disptach({
      type: JOBLISTING.SET_PROPS,
      payload: { gettingLocations: false },
    });
    disptach({
      type: JOBLISTING.GET_LOCATIONS,
      payload: response.data,
    });
  } catch (error) {
    if (error.response.status === 401) {
      history.push("/app/auth");
      localStorage.removeItem("refresh");
      localStorage.removeItem("userToken");
    } else {
      disptach({
        type: JOBLISTING.SET_PROPS,
        payload: { gettingLocations: false },
      });

      if (!error.response) {
        toast.error(error.message || SERVER_ERROR);
        return;
      }
      const { data } = error.response;
      const message = data && data.message ? data.message : error.message;
      toast.error(message);
    }
  }
};

export const resetFilterValue = () => async (disptach) => {
  disptach({
    type: JOBLISTING.RESET_FILTER_VALUE,
    payload: {},
  });
};

export const setFilterValue = (value) => async (disptach) => {
  disptach({
    type: JOBLISTING.SET_FILTER_VALUE,
    payload: { value },
  });
};

export const setStartDateVal = (startDate) => async (disptach) => {
  disptach({
    type: JOBLISTING.SET_START_DATE_VALUE,
    payload: { startDate },
  });
};

export const setEndDateVal = (endDate) => async (disptach) => {
  disptach({
    type: JOBLISTING.SET_END_DATE_VALUE,
    payload: { endDate },
  });
};
