import axios from 'axios';
import { toast } from 'react-toastify';

import { FORGOT_PASSWORD, SERVER_ERROR } from './types';
import setAuthToken from '../../utils/setAuthToken';

//Send Code
export const sendVerificationCode = (values) => async (disptach) => {
  try {
    disptach({
      type: FORGOT_PASSWORD.SET_PROPS,
      payload: { sendingCode: true },
    });
    // setAuthToken();
    delete axios.defaults.headers.common['Authorization'];
    const response = await axios.post(
       `/api/forgot-password/`,
       values
    );
    disptach({
      type: FORGOT_PASSWORD.SET_PROPS,
      payload: { sendingCode: false },
    });
    disptach({
      type: FORGOT_PASSWORD.SEND_CODE,
      payload: response.data,
    });
    if(response.data.Message==="User does not exist"){
      toast.error(response.data.Message);
    }
    
  } catch (error) {
    disptach({
      type: FORGOT_PASSWORD.SET_PROPS,
      payload: { sendingCode: false },
    });

    if (!error.response) {
      toast.error(error.message || SERVER_ERROR);
      return;
    }
    const { data } = error.response;
    const message = data && data.message ? data.message : error.message;
    toast.error(message);
  }
};

//Reset Password
export const resetPassword = (values) => async (disptach) => {
  try {
    disptach({
      type: FORGOT_PASSWORD.SET_PROPS,
      payload: { resetingPassword: true },
    });
    //setAuthToken();
    const response = await axios.put(
       `/api/forgot-password/`,
       values
    );
    disptach({
      type: FORGOT_PASSWORD.SET_PROPS,
      payload: { resetingPassword: false },
    });
    disptach({
      type: FORGOT_PASSWORD.RESET_PASSWORD,
      payload: response.data,
    });
    if(response.data.Message==="Invalid Code or Code has Expired"){
      toast.error(response.data.Message);
    }
    else if(response.data.Message==="Password updated successfully"){
      toast.success(response.data.Message);
    }
    
  } catch (error) {
    disptach({
      type: FORGOT_PASSWORD.SET_PROPS,
      payload: { resetingPassword: false },
    });

    if (!error.response) {
      toast.error(error.message || SERVER_ERROR);
      return;
    }
    const { data } = error.response;
    const message = data && data.message ? data.message : error.message;
    toast.error(message);
  }
};

//Reset Password
export const resetState = () => async (disptach) => {
  disptach({type: FORGOT_PASSWORD.RESET_PROPS});
}
