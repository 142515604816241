import React from "react";
import { Provider } from "react-redux";
import "react-toastify/dist/ReactToastify.min.css";
import "font-awesome/css/font-awesome.min.css";
import "./App.css";
import setAPIBaseUrl from "./utils/setAPIBaseUrl.js";
import store from "./store";
import Layout from "./Layouts/layout";

setAPIBaseUrl();
function App() {
  return (
    <Provider store={store}>
      <Layout />
    </Provider>
  );
}

export default App;
