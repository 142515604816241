import { combineReducers } from 'redux';

import jobListing from './jobListing';
import auth from './auth';
import upload from './upload';
import forgotPassword from './forgotPassword';
import dashboard from './dashboard';

export default combineReducers({
  jobListing,
  auth,
  upload,
  forgotPassword,
  dashboard
});
