export const SERVER_ERROR =
  "Something is technically wrong. Thanks for noticing - we're going to fix it up and have things back to normal soon.";

//Job Listing
export const AUTH = {
  LOGIN_LOADING: "LOGIN_LOADING",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAIL: "LOGIN_FAIL",
  AUTHORISATION_LOADING: "AUTHORISATION_LOADING",
  AUTHORISATION_SUCCESS: "AUTHORISATION_SUCCESS",
  AUTHORISATION_FAIL: "AUTHORISATION_FAIL",
  LOGOUT: "LOGOUT",
};

//Job Listing
export const JOBLISTING = {
  SET_PROPS: "SET_PROPS",
  GET_JOBS_LISTING: "GET_JOBS_LISTING",
  GET_LOCATIONS: "GET_LOCATIONS",
  GET_ALL_JOBS_DATA: "GET_ALL_JOBS_DATA",
  GET_ALL_JOBS_DATA_SUCCESS: "GET_ALL_JOBS_DATA_SUCCESS",
  GET_ALL_JOBS_DATA_FAILURE: "GET_ALL_JOBS_DATA_FAILURE",
  GET_TASK_ID: "GET_TASK_ID",
  GET_TASK_ID_SUCCESS: "GET_TASK_ID_SUCCESS",
  GET_TASK_ID_FAILURE: "GET_TASK_ID_FAILURE",
  SET_FILTER_VALUE: "SET_FILTER_VALUE",
  SET_START_DATE_VALUE: "SET_START_DATE_VALUE",
  SET_END_DATE_VALUE: "SET_END_DATE_VALUE",
  RESET_FILTER_VALUE: "RESET_FILTER_VALUE",
};

//UPLOAD
export const UPLOAD = {
  SET_PROPS: "SET_PROPS",
  UPLOAD_SUCCESS: "UPLOAD_SUCCESS",
  UPLOAD_FAIL: "UPLOAD_FAIL",
  RESET_PROPS: "RESET_PROPS",
};

//Forgot Password
export const FORGOT_PASSWORD = {
  SET_PROPS: "SET_PROPS",
  SEND_CODE: "SEND_CODE",
  RESET_PASSWORD: "RESET_PASSWORD",
  RESET_PROPS: "RESET_PROPS",
};

//Dashboard
export const DASHBOARD = {
  SET_PROPS: "SET_PROPS",
  SET_USER_LOADING: "SET_USER_LOADING",
  GET_USERS: "GET_USERS",
  GET_CLIENT_USERS: "GET_CLIENT_USERS",
  DEL_USER: "DEL_USER",
  EDIT_USER: "EDIT_USER",
  ADD_USER: "ADD_USER",
  ADD_USER_FAILURE: "ADD_USER_FAILURE",
  GET_GROUPS: "GET_GROUPS",
  GET_CLIENT_GROUPS: "GET_CLIENT_GROUPS",
  GET_PERMISSIONS: "GET_PERMISSIONS",
  GET_ALL_GROUPS_WITH_PERMISSIONS: "GET_ALL_GROUPS_WITH_PERMISSIONS",

  CREATE_GROUP: "CREATE_GROUP",
  CREATE_GROUP_SUCCESS: "CREATE_GROUP_SUCCESS",
  CREATE_GROUP_FAILURE: "CREATE_GROUP_FAILURE",

  CREATE_INTERNAL_GROUP: "CREATE_INTERNAL_GROUP",
  CREATE_INTERNAL_GROUP_SUCCESS: "CREATE_INTERNAL_GROUP_SUCCESS",
  CREATE_INTERNAL_GROUP_FAILURE: "CREATE_INTERNAL_GROUP_FAILURE",

  EDIT_GROUP: "EDIT_GROUP",
  EDIT_GROUP_SUCCESS: "EDIT_GROUP_SUCCESS",
  EDIT_GROUP_FAILURE: "EDIT_GROUP_FAILURE",

  EDIT_INTERNAL_GROUP: "EDIT_INTERNAL_GROUP",
  EDIT_INTERNAL_GROUP_SUCCESS: "EDIT_INTERNAL_GROUP_SUCCESS",
  EDIT_INTERNAL_GROUP_FAILURE: "EDIT_INTERNAL_GROUP_FAILURE",

  UPDATE_USER_PERMISSIONS: "UPDATE_USER_PERMISSIONS",
  UPDATE_USER_PERMISSIONS_SUCCESS: "UPDATE_USER_PERMISSIONS_SUCCESS",
  UPDATE_USER_PERMISSIONS_FAILURE: "UPDATE_USER_PERMISSIONS_FAILURE",

  UPDATE_INTERNAL_USER_PERMISSIONS: "UPDATE_INTERNAL_USER_PERMISSIONS",
  UPDATE_INTERNAL_USER_PERMISSIONS_SUCCESS:
    "UPDATE_INTERNAL_USER_PERMISSIONS_SUCCESS",
  UPDATE_INTERNAL_USER_PERMISSIONS_FAILURE:
    "UPDATE_INTERNAL_USER_PERMISSIONS_FAILURE",

  UPDATE_GROUP_PERMISSIONS: "UPDATE_GROUP_PERMISSIONS",
  UPDATE_GROUP_PERMISSIONS_SUCCESS: "UPDATE_GROUP_PERMISSIONS_SUCCESS",
  UPDATE_GROUP_PERMISSIONS_FAILURE: "UPDATE_GROUP_PERMISSIONS_FAILURE",

  DELETE_GROUP: "DELETE_GROUP",
  DELETE_GROUP_SUCCESS: "DELETE_GROUP_SUCCESS",
  DELETE_GROUP_FAILURE: "DELETE_GROUP_FAILURE",

  DELETE_INTERNAL_GROUP: "DELETE_INTERNAL_GROUP",
  DELETE_INTERNAL_GROUP_SUCCESS: "DELETE_INTERNAL_GROUP_SUCCESS",
  DELETE_INTERNAL_GROUP_FAILURE: "DELETE_INTERNAL_GROUP_FAILURE",

  CREATE_GROUP_MODEL: "CREATE_GROUP_MODEL",
  DELETE_GROUP_MODEL: "DELETE_GROUP_MODEL",
  API_USER_MODEL: "API_USER_MODEL",
  SEARCH_GROUP: "SEARCH_GROUP",
  SEARCH_INTERNAL_GROUP: "SEARCH_INTERNAL_GROUP",
  SEARCH_API: "SEARCH_API",
  SEARCH_INTERNAL_API: "SEARCH_INTERNAL_API",
  GET_GROUP_STATS: "GET_GROUP_STATS",
  GET_INTERNAL_GROUP_STATS: "GET_INTERNAL_GROUP_STATS",
  GET_API_STATS: "GET_API_STATS",
  GET_INTERNAL_API_STATS: "GET_INTERNAL_API_STATS",
  RESET_GROUP_ERROR: "RESET_GROUP_ERROR",
};
