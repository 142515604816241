/* eslint-disable import/no-anonymous-default-export */
import { AUTH } from "../actions";

const initialState = {
  
};

export default (state = initialState, action) => {
 const { type, payload } = action;
 switch (type) {
    case AUTH.LOGIN_SUCCESS:
      localStorage.setItem('userToken', payload.access);
      localStorage.setItem('refresh', payload.refresh);
      return {
        ...state,
        isLogin: true,
        isloggingIn: false,
      };
    case AUTH.LOGIN_LOADING:
      return {
        ...state,
        isloggingIn: true,
      };
    case AUTH.LOGIN_FAIL:
      localStorage.removeItem('refresh');
      localStorage.removeItem('userToken');
      return {
        ...state,
        isloggingIn: false,
      }
    case AUTH.AUTHORISATION_LOADING:
      return{
        authLoading: true,
      }
    case AUTH.AUTHORISATION_SUCCESS:
      localStorage.setItem('isAdmin', payload.is_admin);
      return{
        authLoading: false,
        isAuthorised:true,
      }

    case AUTH.AUTHORISATION_FAIL:
      return{
        authLoading: false,
        isAuthorised:false,
      }
    case AUTH.LOGOUT:
      return{
        authLoading: false,
        isAuthorised:false,
      }
    default:
      return state;
 }
};
