/* eslint-disable no-undef */
import axios from 'axios';

const setAPIBaseUrl = () => {
  const baseUrl ='https://webdata-207211.appspot.com';

  axios.defaults.baseURL = baseUrl;
};

export default setAPIBaseUrl;
