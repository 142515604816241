/* eslint-disable import/no-anonymous-default-export */
import { UPLOAD } from "../actions";

const initialState = {
  jobs:[]
};

export default (state = initialState, action) => {
 const { type, payload } = action;
 switch (type) {
  case UPLOAD.SET_PROPS:
   return { ...state, ...payload };
  case UPLOAD.UPLOAD_SUCCESS:
   return {
    ...state,
    data: payload,
    uploadingFile:false,
   };
  case UPLOAD.UPLOAD_FAIL:
   return {
    ...state,
    data: payload,
    uploadingFile:false,
   };
  case UPLOAD.RESET_PROPS:
   return {
    ...state,
    data: null,
    uploadingFile:false,
   };

  default:
   return {
    ...state,
   };
 }
};
