import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import loadable from "@loadable/component";

const GeneralLayout = loadable(() =>
  import("../components/pages/GeneralLayout")
);
const Layout = () => {
  useEffect(() => {
    GeneralLayout.preload();
  }, []);
  return (
    <Router fallback={<span />}>
      <Switch>
        <Route path="/" exact component={() => <Redirect to="/app/auth" />} />
        <Route path="/app/auth" component={GeneralLayout} />
        <Route path="/app/home" component={GeneralLayout} />
        <Route path="/app/search" component={GeneralLayout} />
        <Route path="/app/forgot-password" component={GeneralLayout} />
        <Route path="/app/reset-password" component={GeneralLayout} />
        <Route path="/app/dashboard" component={GeneralLayout} />
        <Route path="/app/client-dashboard" component={GeneralLayout} />
      </Switch>
      <ToastContainer
        position={toast.POSITION.TOP_RIGHT}
        style={{ zIndex: 999999999, padding: "20px" }}
      />
    </Router>
  );
};

export default Layout;
