import axios from "axios";
import { toast } from "react-toastify";

import { DASHBOARD, SERVER_ERROR } from "./types";
import setAuthToken from "../../utils/setAuthToken";

//Get Users
export const getUsers = (history) => async (disptach) => {
  try {
    disptach({
      type: DASHBOARD.SET_PROPS,
      payload: { gettingUsersListing: true },
    });
    setAuthToken();
    const response = await axios.get(`/api/get-users/`);
    disptach({
      type: DASHBOARD.SET_PROPS,
      payload: { gettingUsersListing: false },
    });
    disptach({
      type: DASHBOARD.GET_USERS,
      payload: response.data,
    });
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      history.push("/app/auth");
      localStorage.removeItem("refresh");
      localStorage.removeItem("userToken");
    } else {
      disptach({
        type: DASHBOARD.SET_PROPS,
        payload: { gettingUsersListing: false },
      });

      if (!error.response) {
        toast.error(error.message || SERVER_ERROR);
        return;
      }

      const { data } = error.response;
      const message = data && data.message ? data.message : error.message;
      toast.error(message);
    }
  }
};

//Get Users
export const getClientUsers = (history) => async (disptach) => {
  try {
    disptach({
      type: DASHBOARD.SET_PROPS,
      payload: { gettingUsersListing: true },
    });
    setAuthToken();
    const response = await axios.get(`/api/get-users/?client=1`);
    disptach({
      type: DASHBOARD.SET_PROPS,
      payload: { gettingUsersListing: false },
    });
    disptach({
      type: DASHBOARD.GET_CLIENT_USERS,
      payload: response.data,
    });
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      history.push("/app/auth");
      localStorage.removeItem("refresh");
      localStorage.removeItem("userToken");
    } else {
      disptach({
        type: DASHBOARD.SET_PROPS,
        payload: { gettingUsersListing: false },
      });

      if (!error.response) {
        toast.error(error.message || SERVER_ERROR);
        return;
      }

      const { data } = error.response;
      const message = data && data.message ? data.message : error.message;
      toast.error(message);
    }
  }
};

//Get Groups
export const getGroups = (history) => async (disptach) => {
  try {
    disptach({
      type: DASHBOARD.SET_PROPS,
      payload: { gettingGroups: true },
    });
    setAuthToken();
    const response = await axios.get(`/api/groups/`);
    disptach({
      type: DASHBOARD.GET_GROUPS,
      payload: response.data,
    });
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      history.push("/app/auth");
      localStorage.removeItem("refresh");
      localStorage.removeItem("userToken");
    } else {
      disptach({
        type: DASHBOARD.SET_PROPS,
        payload: { gettingGroups: false },
      });

      if (!error.response) {
        toast.error(error.message || SERVER_ERROR);
        return;
      }

      const { data } = error.response;
      const message = data && data.message ? data.message : error.message;
      toast.error(message);
    }
  }
};

//Get Client Groups
export const getClientGroups = (history) => async (disptach) => {
  try {
    setAuthToken();
    const response = await axios.get(`/api/groups/?client=1`);
    disptach({
      type: DASHBOARD.GET_CLIENT_GROUPS,
      payload: response.data,
    });
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      history.push("/app/auth");
      localStorage.removeItem("refresh");
      localStorage.removeItem("userToken");
    } else {
      disptach({
        type: DASHBOARD.SET_PROPS,
        payload: { gettingGroups: false },
      });

      if (!error.response) {
        toast.error(error.message || SERVER_ERROR);
        return;
      }

      const { data } = error.response;
      const message = data && data.message ? data.message : error.message;
      toast.error(message);
    }
  }
};

//Update User Permissions
export const updateUserPermissions = (id, values, history) => async (
  disptach
) => {
  try {
    disptach({
      type: DASHBOARD.UPDATE_USER_PERMISSIONS,
      payload: {},
    });
    setAuthToken();
    const response = await axios.put(`/api/api-stats/${id}/?client=1`, values);
    const GroupStatsResponse = await axios.get(`/api/group-stats/?client=1`);
    disptach({
      type: DASHBOARD.GET_GROUP_STATS,
      payload: GroupStatsResponse.data,
    });
    disptach({
      type: DASHBOARD.UPDATE_USER_PERMISSIONS_SUCCESS,
      payload: response.data,
    });
    toast.success("API permissions updated successfully !");
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      history.push("/app/auth");
      localStorage.removeItem("refresh");
      localStorage.removeItem("userToken");
    } else {
      disptach({
        type: DASHBOARD.UPDATE_USER_PERMISSIONS_FAILURE,
        payload: {},
      });

      if (!error.response) {
        toast.error(error.message || SERVER_ERROR);
        return;
      }

      const { data } = error.response;
      const message = data && data.message ? data.message : error.message;
      toast.error(message);
    }
  }
};

//Update Internal User Permissions
export const updateInternalUserPermissions = (id, values, history) => async (
  disptach
) => {
  try {
    disptach({
      type: DASHBOARD.UPDATE_INTERNAL_USER_PERMISSIONS,
      payload: {},
    });
    setAuthToken();
    const response = await axios.put(`/api/api-stats/${id}/`, values);
    const GroupResponse = await axios.get(`/api/group-stats/`);
    disptach({
      type: DASHBOARD.GET_INTERNAL_GROUP_STATS,
      payload: GroupResponse.data,
    });
    disptach({
      type: DASHBOARD.UPDATE_INTERNAL_USER_PERMISSIONS_SUCCESS,
      payload: response.data,
    });
    toast.success("API permissions updated successfully !");
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      history.push("/app/auth");
      localStorage.removeItem("refresh");
      localStorage.removeItem("userToken");
    } else {
      disptach({
        type: DASHBOARD.UPDATE_INTERNAL_USER_PERMISSIONS_FAILURE,
        payload: {},
      });

      if (!error.response) {
        toast.error(error.message || SERVER_ERROR);
        return;
      }

      const { data } = error.response;
      const message = data && data.message ? data.message : error.message;
      toast.error(message);
    }
  }
};

//Delete User
export const delUser = (screen, values, history) => async (disptach) => {
  try {
    disptach({
      type: DASHBOARD.SET_PROPS,
      payload: { deletingUser: true },
    });
    disptach({
      type: DASHBOARD.SET_USER_LOADING,
      payload: {},
    });
    setAuthToken();
    const response = await axios.delete(`/api/users/${values.userId}`);
    if (screen === "Dashboard") {
      const usersResponse = await axios.get("/api/get-users/");
      const InternalGroupStatsResponse = await axios.get("/api/group-stats/");
      disptach({
        type: DASHBOARD.GET_INTERNAL_GROUP_STATS,
        payload: InternalGroupStatsResponse.data,
      });

      disptach({
        type: DASHBOARD.GET_USERS,
        payload: usersResponse.data,
      });
    } else {
      const clientUsersResponse = await axios.get(`/api/get-users/?client=1`);
      const GroupStatsResponse = await axios.get(`/api/group-stats/?client=1`);
      disptach({
        type: DASHBOARD.GET_GROUP_STATS,
        payload: GroupStatsResponse.data,
      });

      disptach({
        type: DASHBOARD.GET_CLIENT_USERS,
        payload: clientUsersResponse.data,
      });
    }

    disptach({
      type: DASHBOARD.SET_PROPS,
      payload: { deletingUser: false },
    });
    disptach({
      type: DASHBOARD.DEL_USER,
      payload: response.data,
    });
    toast.success("Deleted User Successfully");
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      history.push("/app/auth");
      localStorage.removeItem("refresh");
      localStorage.removeItem("userToken");
    } else {
      disptach({
        type: DASHBOARD.SET_PROPS,
        payload: { deletingUser: false },
      });
      if (!error.response) {
        toast.error(error.message || SERVER_ERROR);
        return;
      }
      const { data } = error.response;
      const message = data && data.message ? data.message : error.message;
      toast.error(message);
    }
  }
};

//Add User
export const AddUser = (screen, values, history) => async (disptach) => {
  try {
    disptach({
      type: DASHBOARD.SET_PROPS,
      payload: { editingUser: true },
    });
    disptach({
      type: DASHBOARD.SET_USER_LOADING,
      payload: {},
    });
    setAuthToken();
    const groupIds = [];

    if (values.groups) {
      if (values.groups.length) {
        values.groups.forEach((element) => {
          groupIds.push(element.id);
        });
        values.groups = groupIds;
      }
    } else {
      values.groups = [];
    }
    values.is_active = true;
    const response = await axios.post(`/api/users/`, values);

    if (screen === "Dashboard") {
      const InternalUsersResponse = await axios.get("/api/get-users/");
      const InternalGroupStatsResponse = await axios.get("/api/group-stats/");
      disptach({
        type: DASHBOARD.GET_INTERNAL_GROUP_STATS,
        payload: InternalGroupStatsResponse.data,
      });

      disptach({
        type: DASHBOARD.GET_USERS,
        payload: InternalUsersResponse.data,
      });
    } else {
      const clientUsersResponse = await axios.get(`/api/get-users/?client=1`);
      const GroupStatsResponse = await axios.get(`/api/group-stats/?client=1`);
      disptach({
        type: DASHBOARD.GET_GROUP_STATS,
        payload: GroupStatsResponse.data,
      });

      disptach({
        type: DASHBOARD.GET_CLIENT_USERS,
        payload: clientUsersResponse.data,
      });
    }

    disptach({
      type: DASHBOARD.SET_PROPS,
      payload: { editingUser: false },
    });
    disptach({
      type: DASHBOARD.ADD_USER,
      payload: response.data,
    });
    toast.success("Created Successfully");
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      history.push("/app/auth");
      localStorage.removeItem("refresh");
      localStorage.removeItem("userToken");
    } else {
      disptach({
        type: DASHBOARD.SET_PROPS,
        payload: { editingUser: false },
      });
      disptach({
        type: DASHBOARD.ADD_USER_FAILURE,
        payload: {},
      });
      if (typeof error.response.data === "object") {
        toast.error("A user with that username already exists.");
        return;
      } else {
        if (!error.response) {
          toast.error(error.message || SERVER_ERROR);
          return;
        }
      }
      const { data } = error.response;
      const message = data && data.message ? data.message : error.message;
      toast.error(message);
    }
  }
};

//Edit User
export const EditUser = (screen, values, history) => async (disptach) => {
  try {
    disptach({
      type: DASHBOARD.SET_PROPS,
      payload: { editingUser: true },
    });
    disptach({
      type: DASHBOARD.SET_USER_LOADING,
      payload: {},
    });
    setAuthToken();
    const groupIds = [];

    if (values.groups) {
      if (values.groups.length) {
        values.groups.forEach((element) => {
          groupIds.push(element.id);
        });
        values.groups = groupIds;
      }
    } else {
      values.groups = [];
    }
    const response = await axios.patch(`/api/users/${values.id}/`, values);

    if (screen === "Dashboard") {
      const InternalUsersResponse = await axios.get("/api/get-users/");
      const InternalGroupStatsResponse = await axios.get("/api/group-stats/");
      disptach({
        type: DASHBOARD.GET_INTERNAL_GROUP_STATS,
        payload: InternalGroupStatsResponse.data,
      });

      disptach({
        type: DASHBOARD.GET_USERS,
        payload: InternalUsersResponse.data,
      });
    } else {
      const clientUsersResponse = await axios.get(`/api/get-users/?client=1`);
      const GroupStatsResponse = await axios.get(`/api/group-stats/?client=1`);
      disptach({
        type: DASHBOARD.GET_CLIENT_USERS,
        payload: clientUsersResponse.data,
      });
      disptach({
        type: DASHBOARD.GET_GROUP_STATS,
        payload: GroupStatsResponse.data,
      });
    }

    disptach({
      type: DASHBOARD.SET_PROPS,
      payload: { editingUser: false },
    });
    disptach({
      type: DASHBOARD.EDIT_USER,
      payload: response.data,
    });
    toast.success("Updated Successfully");
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      history.push("/app/auth");
      localStorage.removeItem("refresh");
      localStorage.removeItem("userToken");
    } else {
      disptach({
        type: DASHBOARD.SET_PROPS,
        payload: { editingUser: false },
      });
      disptach({
        type: DASHBOARD.ADD_USER_FAILURE,
        payload: {},
      });
      console.log("error", error);

      if (!error.response) {
        toast.error(error.message || SERVER_ERROR);
        return;
      }
      const { data } = error.response;
      const message = data && data.message ? data.message : error.message;
      toast.error(message);
    }
  }
};

//Get Group Stats
export const getGroupStats = (history) => async (disptach) => {
  try {
    setAuthToken();
    const response = await axios.get(`/api/group-stats/?client=1`);
    disptach({
      type: DASHBOARD.GET_GROUP_STATS,
      payload: response.data,
    });
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      history.push("/app/auth");
      localStorage.removeItem("refresh");
      localStorage.removeItem("userToken");
    } else {
      if (!error.response) {
        toast.error(error.message || SERVER_ERROR);
        return;
      }

      const { data } = error.response;
      const message = data && data.message ? data.message : error.message;
      toast.error(message);
    }
  }
};

//Get Internal Group Stats
export const getInternalGroupStats = (history) => async (disptach) => {
  try {
    setAuthToken();
    const response = await axios.get(`/api/group-stats/`);
    disptach({
      type: DASHBOARD.GET_INTERNAL_GROUP_STATS,
      payload: response.data,
    });
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      history.push("/app/auth");
      localStorage.removeItem("refresh");
      localStorage.removeItem("userToken");
    } else {
      if (!error.response) {
        toast.error(error.message || SERVER_ERROR);
        return;
      }

      const { data } = error.response;
      const message = data && data.message ? data.message : error.message;
      toast.error(message);
    }
  }
};

//Get API Stats
export const getApiStats = (history) => async (disptach) => {
  try {
    setAuthToken();
    const response = await axios.get(`/api/api-stats/?client=1`);
    disptach({
      type: DASHBOARD.GET_API_STATS,
      payload: response.data,
    });
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      history.push("/app/auth");
      localStorage.removeItem("refresh");
      localStorage.removeItem("userToken");
    } else {
      if (!error.response) {
        toast.error(error.message || SERVER_ERROR);
        return;
      }

      const { data } = error.response;
      const message = data && data.message ? data.message : error.message;
      toast.error(message);
    }
  }
};

//Get API Internal Stats
export const getInternalApiStats = (history) => async (disptach) => {
  try {
    setAuthToken();
    const response = await axios.get("/api/api-stats/");
    disptach({
      type: DASHBOARD.GET_INTERNAL_API_STATS,
      payload: response.data,
    });
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      history.push("/app/auth");
      localStorage.removeItem("refresh");
      localStorage.removeItem("userToken");
    } else {
      if (!error.response) {
        toast.error(error.message || SERVER_ERROR);
        return;
      }

      const { data } = error.response;
      const message = data && data.message ? data.message : error.message;
      toast.error(message);
    }
  }
};

//Get Permissions
export const getPermissions = (history) => async (disptach) => {
  try {
    setAuthToken();
    const response = await axios.get(`/api/permissions/`);
    disptach({
      type: DASHBOARD.GET_PERMISSIONS,
      payload: response.data,
    });
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      history.push("/app/auth");
      localStorage.removeItem("refresh");
      localStorage.removeItem("userToken");
    } else {
      if (!error.response) {
        toast.error(error.message || SERVER_ERROR);
        return;
      }

      const { data } = error.response;
      const message = data && data.message ? data.message : error.message;
      toast.error(message);
    }
  }
};

//Get All Groups With Permissions
export const getAllGroupsWithPermissions = (history) => async (disptach) => {
  try {
    setAuthToken();
    const response = await axios.get("/api/group-permissions/");
    disptach({
      type: DASHBOARD.GET_ALL_GROUPS_WITH_PERMISSIONS,
      payload: response.data,
    });
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      history.push("/app/auth");
      localStorage.removeItem("refresh");
      localStorage.removeItem("userToken");
    } else {
      if (!error.response) {
        toast.error(error.message || SERVER_ERROR);
        return;
      }

      const { data } = error.response;
      const message = data && data.message ? data.message : error.message;
      toast.error(message);
    }
  }
};

//Update Group Permissions
export const UpdateGroupPermissions = (groupId, values, history) => async (
  disptach
) => {
  try {
    disptach({
      type: DASHBOARD.UPDATE_GROUP_PERMISSIONS,
      payload: {},
    });
    setAuthToken();
    const response = await axios.put(
      `/api/change-group-permissions/${groupId}`,
      values
    );
    const permissionsResponse = await axios.get("/api/group-permissions/");
    disptach({
      type: DASHBOARD.GET_ALL_GROUPS_WITH_PERMISSIONS,
      payload: permissionsResponse.data,
    });
    disptach({
      type: DASHBOARD.UPDATE_GROUP_PERMISSIONS_SUCCESS,
      payload: response.data,
    });
    toast.success("Group updated Successfully !");
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      history.push("/app/auth");
      localStorage.removeItem("refresh");
      localStorage.removeItem("userToken");
    } else {
      disptach({
        type: DASHBOARD.UPDATE_GROUP_PERMISSIONS_FAILURE,
        payload: {},
      });
      if (!error.response) {
        toast.error(error.message || SERVER_ERROR);
        return;
      }
      const { data } = error.response;
      const message = data && data.message ? data.message : error.message;
      toast.error(message);
    }
  }
};

//Create Group
export const createNewGroup = (values, history) => async (disptach) => {
  try {
    disptach({
      type: DASHBOARD.CREATE_GROUP,
      payload: {},
    });
    setAuthToken();
    const response = await axios.post("/api/group-stats/?client=1", values);
    const clientGroupsResponse = await axios.get(`/api/groups/?client=1`);
    const clientUsersResponse = await axios.get(`/api/get-users/?client=1`);
    disptach({
      type: DASHBOARD.GET_CLIENT_USERS,
      payload: clientUsersResponse.data,
    });
    disptach({
      type: DASHBOARD.GET_CLIENT_GROUPS,
      payload: clientGroupsResponse.data,
    });
    disptach({
      type: DASHBOARD.CREATE_GROUP_SUCCESS,
      payload: response.data,
    });
    toast.success("Group created Successfully !");
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      history.push("/app/auth");
      localStorage.removeItem("refresh");
      localStorage.removeItem("userToken");
    } else {
      if (error && error.response && error.response.status === 400) {
        disptach({
          type: DASHBOARD.CREATE_GROUP_FAILURE,
          payload: {},
        });
      }
      if (!error.response) {
        toast.error(error.message || SERVER_ERROR);
        return;
      }
      const { data } = error.response;
      const message = data && data.message ? data.message : error.message;
      if (error && error.response && error.response.status !== 400) {
        toast.error(message);
      }
    }
  }
};

//Create Internal Group
export const createInternalNewGroup = (values, history) => async (disptach) => {
  try {
    disptach({
      type: DASHBOARD.CREATE_INTERNAL_GROUP,
      payload: {},
    });
    setAuthToken();
    const response = await axios.post("/api/group-stats/", values);
    const groupsResponse = await axios.get("/api/groups/");
    const usersResponse = await axios.get("/api/get-users/");

    disptach({
      type: DASHBOARD.GET_USERS,
      payload: usersResponse.data,
    });
    disptach({
      type: DASHBOARD.GET_GROUPS,
      payload: groupsResponse.data,
    });
    disptach({
      type: DASHBOARD.CREATE_INTERNAL_GROUP_SUCCESS,
      payload: response.data,
    });
    toast.success("Group created Successfully !");
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      history.push("/app/auth");
      localStorage.removeItem("refresh");
      localStorage.removeItem("userToken");
    } else {
      if (error && error.response && error.response.status === 400) {
        disptach({
          type: DASHBOARD.CREATE_INTERNAL_GROUP_FAILURE,
          payload: {},
        });
      }
      if (!error.response) {
        toast.error(error.message || SERVER_ERROR);
        return;
      }
      const { data } = error.response;
      const message = data && data.message ? data.message : error.message;
      if (error && error.response && error.response.status !== 400) {
        toast.error(message);
      }
    }
  }
};

//Edit Group
export const editGroup = (groupId, values, history) => async (disptach) => {
  try {
    disptach({
      type: DASHBOARD.EDIT_GROUP,
      payload: {},
    });
    setAuthToken();
    const response = await axios.put(
      `/api/group-stats/${groupId}/?client=1`,
      values
    );
    const clientGroupsResponse = await axios.get(`/api/groups/?client=1`);
    const clientUsersResponse = await axios.get(`/api/get-users/?client=1`);
    const apiResponse = await axios.get(`/api/api-stats/?client=1`);

    disptach({
      type: DASHBOARD.GET_API_STATS,
      payload: apiResponse.data,
    });
    disptach({
      type: DASHBOARD.GET_CLIENT_USERS,
      payload: clientUsersResponse.data,
    });
    disptach({
      type: DASHBOARD.GET_CLIENT_GROUPS,
      payload: clientGroupsResponse.data,
    });
    disptach({
      type: DASHBOARD.EDIT_GROUP_SUCCESS,
      payload: response.data,
    });
    toast.success("Group updated Successfully !");
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      history.push("/app/auth");
      localStorage.removeItem("refresh");
      localStorage.removeItem("userToken");
    } else {
      if (error && error.response && error.response.status === 400) {
        disptach({
          type: DASHBOARD.EDIT_GROUP_FAILURE,
          payload: {},
        });
      }
      if (!error.response) {
        toast.error(error.message || SERVER_ERROR);
        return;
      }
      const { data } = error.response;
      const message = data && data.message ? data.message : error.message;
      if (error && error.response && error.response.status !== 400) {
        toast.error(message);
      }
    }
  }
};

//Edit Internal Group
export const editInternalGroup = (groupId, values, history) => async (
  disptach
) => {
  try {
    disptach({
      type: DASHBOARD.EDIT_INTERNAL_GROUP,
      payload: {},
    });
    setAuthToken();
    const response = await axios.put(`/api/group-stats/${groupId}/`, values);
    const groupsResponse = await axios.get("/api/groups/");
    const usersResponse = await axios.get("/api/get-users/");
    const apiResponse = await axios.get("/api/api-stats/");
    disptach({
      type: DASHBOARD.GET_INTERNAL_API_STATS,
      payload: apiResponse.data,
    });

    disptach({
      type: DASHBOARD.GET_USERS,
      payload: usersResponse.data,
    });
    disptach({
      type: DASHBOARD.GET_GROUPS,
      payload: groupsResponse.data,
    });
    disptach({
      type: DASHBOARD.EDIT_INTERNAL_GROUP_SUCCESS,
      payload: response.data,
    });
    toast.success("Group updated Successfully !");
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      history.push("/app/auth");
      localStorage.removeItem("refresh");
      localStorage.removeItem("userToken");
    } else {
      if (error && error.response && error.response.status === 400) {
        disptach({
          type: DASHBOARD.EDIT_INTERNAL_GROUP_FAILURE,
          payload: {},
        });
      }
      if (!error.response) {
        toast.error(error.message || SERVER_ERROR);
        return;
      }
      const { data } = error.response;
      const message = data && data.message ? data.message : error.message;
      if (error && error.response && error.response.status !== 400) {
        toast.error(message);
      }
    }
  }
};

//Delete Group
export const deleteGroup = (groupId, history) => async (disptach) => {
  try {
    disptach({
      type: DASHBOARD.DELETE_GROUP,
      payload: {},
    });
    setAuthToken();
    const response = await axios.delete(
      `/api/group-stats/${groupId}/?client=1`
    );
    const clientGroupsResponse = await axios.get(`/api/groups/?client=1`);
    const clientUsersResponse = await axios.get(`/api/get-users/?client=1`);
    const apiResponse = await axios.get(`/api/api-stats/?client=1`);
    disptach({
      type: DASHBOARD.GET_API_STATS,
      payload: apiResponse.data,
    });

    disptach({
      type: DASHBOARD.GET_CLIENT_USERS,
      payload: clientUsersResponse.data,
    });
    disptach({
      type: DASHBOARD.GET_CLIENT_GROUPS,
      payload: clientGroupsResponse.data,
    });
    disptach({
      type: DASHBOARD.DELETE_GROUP_SUCCESS,
      payload: { id: groupId },
    });
    toast.success("Group deleted Successfully !");
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      history.push("/app/auth");
      localStorage.removeItem("refresh");
      localStorage.removeItem("userToken");
    } else {
      disptach({
        type: DASHBOARD.DELETE_GROUP_FAILURE,
        payload: {},
      });
      if (!error.response) {
        toast.error(error.message || SERVER_ERROR);
        return;
      }
      const { data } = error.response;
      const message = data && data.message ? data.message : error.message;
      toast.error(message);
    }
  }
};

//Delete Internal Group
export const deleteInternalGroup = (groupId, history) => async (disptach) => {
  try {
    disptach({
      type: DASHBOARD.DELETE_INTERNAL_GROUP,
      payload: {},
    });
    setAuthToken();
    const response = await axios.delete(`/api/group-stats/${groupId}/`);
    const internalGroups = await axios.get("/api/groups/");
    const userResponse = await axios.get("/api/get-users/");
    const apiResponse = await axios.get("/api/api-stats/");
    disptach({
      type: DASHBOARD.GET_INTERNAL_API_STATS,
      payload: apiResponse.data,
    });
    disptach({
      type: DASHBOARD.GET_USERS,
      payload: userResponse.data,
    });
    disptach({
      type: DASHBOARD.GET_GROUPS,
      payload: internalGroups.data,
    });
    disptach({
      type: DASHBOARD.DELETE_INTERNAL_GROUP_SUCCESS,
      payload: { id: groupId },
    });
    toast.success("Group deleted Successfully !");
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      history.push("/app/auth");
      localStorage.removeItem("refresh");
      localStorage.removeItem("userToken");
    } else {
      disptach({
        type: DASHBOARD.DELETE_INTERNAL_GROUP_FAILURE,
        payload: {},
      });
      if (!error.response) {
        toast.error(error.message || SERVER_ERROR);
        return;
      }
      const { data } = error.response;
      const message = data && data.message ? data.message : error.message;
      toast.error(message);
    }
  }
};

export const createModel = () => async (disptach) => {
  disptach({
    type: DASHBOARD.CREATE_GROUP_MODEL,
    payload: {},
  });
};

export const deleteGroupModel = () => async (disptach) => {
  disptach({
    type: DASHBOARD.DELETE_GROUP_MODEL,
    payload: {},
  });
};

export const apiUserModel = () => async (disptach) => {
  disptach({
    type: DASHBOARD.API_USER_MODEL,
    payload: {},
  });
};

export const searchGroup = (search) => async (disptach) => {
  disptach({
    type: DASHBOARD.SEARCH_GROUP,
    payload: { search },
  });
};

export const searchInternalGroup = (search) => async (disptach) => {
  disptach({
    type: DASHBOARD.SEARCH_INTERNAL_GROUP,
    payload: { search },
  });
};

export const searchApi = (val) => async (disptach) => {
  disptach({
    type: DASHBOARD.SEARCH_API,
    payload: { val },
  });
};

export const searchInternalApi = (val) => async (disptach) => {
  disptach({
    type: DASHBOARD.SEARCH_INTERNAL_API,
    payload: { val },
  });
};

export const resetGroupError = () => async (disptach) => {
  disptach({
    type: DASHBOARD.RESET_GROUP_ERROR,
    payload: {},
  });
};
