/* eslint-disable import/no-anonymous-default-export */
import { FORGOT_PASSWORD } from "../actions";

const initialState = {
  code:null,
  reset:null,
};

export default (state = initialState, action) => {
 const { type, payload } = action;
 switch (type) {
  case FORGOT_PASSWORD.SET_PROPS:
   return { ...state, ...payload };
  case FORGOT_PASSWORD.SEND_CODE:
   return {
    ...state,
    code: payload,
   };
  case FORGOT_PASSWORD.RESET_PASSWORD:
   return {
    ...state,
    reset: payload,
   };
  case FORGOT_PASSWORD.RESET_PROPS:
   return {
    ...state,
    reset: null,
    code: null,
   };
   default:
   return {
    ...state,
   };
 }
};
