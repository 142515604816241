import axios from 'axios';

const setAuthToken = async (token) => {
  
  const authtoken = token
    ? token
    : localStorage.userToken
    ? localStorage.userToken
    : null;
  if (authtoken) {
    axios.defaults.headers.common = {'Authorization': `Bearer ${authtoken}`}
  } else {
    delete axios.defaults.headers.common['Authorization'];
  }
};

export const setHeaders = async (token = null) => ({
  'auth-token': token ? token : await localStorage.authtoken,
});

export default setAuthToken;
