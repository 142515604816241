/* eslint-disable import/no-anonymous-default-export */
import { DASHBOARD } from "../actions";
var _ = require("lodash");
const initialState = {
  users: [],
  userLoading: false,
  internalUsersList: [],
  clientUsers: [],
  delUser: false,
  editUser: false,
  groups: [],
  clientGroups: [],
  clientUserGroups: [],
  groupStats: [],
  tempGroupState: [],
  groupInternalStats: [],
  tempGroupInternalState: [],
  groupIncrement: 0,
  apiStats: [],
  tempApiState: [],
  internalApiStats: [],
  tempInternalApiState: [],
  apiIncrement: 0,
  permissions: [],
  groupsWithPermissions: [],
  totalGroupsList: [],
  updatingUserPermissions: false,
  deletingGroup: false,
  creatingGroup: false,
  editingGroup: false,
  updatingGroup: false,
  creteModelOpen: false,
  deleteModelOpen: false,
  createIsOpen: false,
  deleteIsOpen: false,
  apiModelIsOpen: false,
  duplicateGroupError: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case DASHBOARD.SET_PROPS:
      return { ...state, ...payload };

    case DASHBOARD.SET_USER_LOADING:
      return { ...state, userLoading: true };

    case DASHBOARD.ADD_USER_FAILURE:
      return { ...state, userLoading: false };

    case DASHBOARD.GET_USERS:
      var internalUsers = [];
      if (payload.result && payload.result.length > 0) {
        for (let i = 0; i < payload.result.length; i++) {
          const data = {
            name:
              payload.result[i].first_name + " " + payload.result[i].last_name,
            id: payload.result[i].id,
          };
          internalUsers.push(data);
        }
        const obj = { name: "Select All", id: "*" };
        internalUsers.unshift(obj);
      }
      return {
        ...state,
        users: payload.result,
        internalUsersList: internalUsers,
        editUser: false,
        delUser: false,
        addUser: false,
      };
    case DASHBOARD.GET_CLIENT_USERS:
      var usersList = [];
      if (payload.result && payload.result.length > 0) {
        for (let i = 0; i < payload.result.length; i++) {
          const data = {
            name:
              payload.result[i].first_name + " " + payload.result[i].last_name,
            id: payload.result[i].id,
          };
          usersList.push(data);
        }
        const obj = { name: "Select All", id: "*" };
        usersList.unshift(obj);
      }

      return {
        ...state,
        clientUsers: payload.result,
        clientUsersList: usersList,
        editUser: false,
        delUser: false,
        addUser: false,
      };
    case DASHBOARD.GET_GROUPS:
      let groupList = [];
      let internalGroups = [];
      if (payload && payload.length > 0) {
        for (let i = 0; i < payload.length; i++) {
          const data = {
            value: payload[i].name,
            id: payload[i].id,
          };
          groupList.push(data);
          internalGroups.push(data);
        }
        const object = { value: "Select All", id: "*" };
        internalGroups.unshift(object);
      }
      return {
        ...state,
        groups: groupList,
        internalUserGroups: internalGroups,
      };

    case DASHBOARD.GET_CLIENT_GROUPS:
      var groupsList = [];
      var groupsForUSers = [];
      if (payload && payload.length > 0) {
        for (let i = 0; i < payload.length; i++) {
          const data = {
            value: payload[i].name,
            id: payload[i].id,
          };
          groupsList.push(data);
          groupsForUSers.push(data);
        }
        const obj = { value: "Select All", id: "*" };
        groupsList.unshift(obj);
      }

      return {
        ...state,
        clientGroups: groupsList,
        clientUserGroups: groupsForUSers,
      };
    case DASHBOARD.DEL_USER: {
      // const tempUsers = state.users;
      // tempUsers.forEach((el, i) => {
      //   if (payload.id === el.id) {
      //     tempUsers.splice(i, 1);
      //   }
      // });
      return {
        ...state,
        editUser: true,
        userLoading: false,
        // users: [...tempUsers],
      };
    }

    case DASHBOARD.EDIT_USER: {
      // const tempUsers = state.users;
      // tempUsers.forEach((el, i) => {
      //   if (payload.id === el.id) {
      //     tempUsers.splice(i, 1);
      //   }
      // });
      return {
        ...state,
        editUser: true,
        userLoading: false,
        // users: [payload, ...tempUsers],
      };
    }

    case DASHBOARD.ADD_USER:
      return {
        ...state,
        addUser: true,
        userLoading: false,
        // users: [payload, ...state.users],
      };
    case DASHBOARD.GET_PERMISSIONS:
      var permissionList = [];
      if (payload && payload.length > 0) {
        for (let i = 0; i < payload.length; i++) {
          const data = {
            name: payload[i].name,
            id: payload[i].id,
          };
          permissionList.push(data);
        }
        const obj = { name: "Select All", id: "*" };
        permissionList.unshift(obj);
      }
      return {
        ...state,
        permissions: permissionList,
      };
    case DASHBOARD.GET_ALL_GROUPS_WITH_PERMISSIONS:
      let sortedArr = _.sortBy(payload, function (x) {
        return x.name.toLowerCase();
      });
      return {
        ...state,
        groupsWithPermissions: sortedArr,
        totalGroupsList: sortedArr,
      };

    //Update User Permissions
    case DASHBOARD.UPDATE_USER_PERMISSIONS:
      return {
        ...state,
        updatingUserPermissions: true,
      };
    case DASHBOARD.UPDATE_USER_PERMISSIONS_SUCCESS:
      const apiIndex = state.apiStats.findIndex(
        (item) => item.id === payload.id
      );
      if (apiIndex > -1) {
        state.apiStats[apiIndex] = payload;
        state.tempApiState[apiIndex] = payload;
      }
      return {
        ...state,
        updatingUserPermissions: false,
        apiModelIsOpen: false,
        apiIncrement: state.apiIncrement + 1,
      };
    case DASHBOARD.UPDATE_USER_PERMISSIONS_FAILURE:
      return {
        ...state,
        updatingUserPermissions: false,
        apiModelIsOpen: false,
      };

    //Update Internal User Permissions
    case DASHBOARD.UPDATE_INTERNAL_USER_PERMISSIONS:
      return {
        ...state,
        updatingUserPermissions: true,
      };
    case DASHBOARD.UPDATE_INTERNAL_USER_PERMISSIONS_SUCCESS:
      const apiInd = state.internalApiStats.findIndex(
        (item) => item.id === payload.id
      );
      if (apiInd > -1) {
        state.internalApiStats[apiInd] = payload;
        state.tempInternalApiState[apiInd] = payload;
      }
      return {
        ...state,
        updatingUserPermissions: false,
        apiModelIsOpen: false,
        apiIncrement: state.apiIncrement + 1,
      };
    case DASHBOARD.UPDATE_INTERNAL_USER_PERMISSIONS_FAILURE:
      return {
        ...state,
        updatingUserPermissions: false,
        apiModelIsOpen: false,
      };

    //Delete Group
    case DASHBOARD.DELETE_GROUP:
      return {
        ...state,
        deletingGroup: true,
      };
    case DASHBOARD.DELETE_GROUP_SUCCESS:
      let groups = state.tempGroupState;
      const i = groups.findIndex((item) => item.id === payload.id);
      if (i > -1) {
        groups.splice(i, 1);
      }
      return {
        ...state,
        deletingGroup: false,
        tempGroupState: groups,
        groupStats: groups,
        deleteIsOpen: false,
        groupIncrement: state.groupIncrement + 1,
      };
    case DASHBOARD.DELETE_GROUP_FAILURE:
      return {
        ...state,
        deletingGroup: false,
      };

    //Delete Internal Group
    case DASHBOARD.DELETE_INTERNAL_GROUP:
      return {
        ...state,
        deletingGroup: true,
      };
    case DASHBOARD.DELETE_INTERNAL_GROUP_SUCCESS:
      let userGroup = state.tempGroupInternalState;
      const ind = userGroup.findIndex((item) => item.id === payload.id);
      if (ind > -1) {
        userGroup.splice(ind, 1);
      }
      return {
        ...state,
        deletingGroup: false,
        tempGroupInternalState: userGroup,
        groupInternalStats: userGroup,
        deleteIsOpen: false,
        groupIncrement: state.groupIncrement + 1,
      };
    case DASHBOARD.DELETE_INTERNAL_GROUP_FAILURE:
      return {
        ...state,
        deletingGroup: false,
      };

    // update group permissions
    case DASHBOARD.UPDATE_GROUP_PERMISSIONS:
      return {
        ...state,
        updatingGroup: true,
      };
    case DASHBOARD.UPDATE_GROUP_PERMISSIONS_SUCCESS:
      return {
        ...state,
        updatingGroup: false,
        createIsOpen: false,
      };
    case DASHBOARD.UPDATE_GROUP_PERMISSIONS_FAILURE:
      return {
        ...state,
        updatingGroup: false,
      };

    // Create Group
    case DASHBOARD.CREATE_GROUP:
      return {
        ...state,
        creatingGroup: true,
        duplicateGroupError: false,
      };
    case DASHBOARD.CREATE_GROUP_SUCCESS:
      state.groupStats.push(payload);
      state.tempGroupState.push(payload);
      return {
        ...state,
        creatingGroup: false,
        createIsOpen: false,
        groupIncrement: state.groupIncrement + 1,
        duplicateGroupError: false,
      };
    case DASHBOARD.CREATE_GROUP_FAILURE:
      return {
        ...state,
        creatingGroup: false,
        duplicateGroupError: true,
      };

    // Create Internal Group
    case DASHBOARD.CREATE_INTERNAL_GROUP:
      return {
        ...state,
        creatingGroup: true,
        duplicateGroupError: false,
      };
    case DASHBOARD.CREATE_INTERNAL_GROUP_SUCCESS:
      state.groupInternalStats.push(payload);
      state.tempGroupInternalState.push(payload);
      return {
        ...state,
        creatingGroup: false,
        createIsOpen: false,
        groupIncrement: state.groupIncrement + 1,
        duplicateGroupError: false,
      };
    case DASHBOARD.CREATE_INTERNAL_GROUP_FAILURE:
      return {
        ...state,
        creatingGroup: false,
        duplicateGroupError: true,
      };

    // Edit Group
    case DASHBOARD.EDIT_GROUP:
      return {
        ...state,
        editingGroup: true,
        duplicateGroupError: false,
      };
    case DASHBOARD.EDIT_GROUP_SUCCESS:
      let groupsClientData = state.tempGroupState;
      const index = groupsClientData.findIndex(
        (item) => item.id === payload.id
      );
      if (index > -1) {
        groupsClientData[index] = payload;
      }
      return {
        ...state,
        editingGroup: false,
        createIsOpen: false,
        groupStats: groupsClientData,
        tempGroupState: groupsClientData,
        groupIncrement: state.groupIncrement + 1,
        duplicateGroupError: false,
      };
    case DASHBOARD.EDIT_GROUP_FAILURE:
      return {
        ...state,
        editingGroup: false,
        duplicateGroupError: true,
      };

    // Edit Internal Group
    case DASHBOARD.EDIT_INTERNAL_GROUP:
      return {
        ...state,
        editingGroup: true,
        duplicateGroupError: false,
      };
    case DASHBOARD.EDIT_INTERNAL_GROUP_SUCCESS:
      let groupsData = state.tempGroupInternalState;
      const findIndex = groupsData.findIndex((item) => item.id === payload.id);
      if (findIndex > -1) {
        groupsData[findIndex] = payload;
      }
      return {
        ...state,
        editingGroup: false,
        createIsOpen: false,
        groupInternalStats: groupsData,
        tempGroupInternalState: groupsData,
        groupIncrement: state.groupIncrement + 1,
        duplicateGroupError: false,
      };
    case DASHBOARD.EDIT_INTERNAL_GROUP_FAILURE:
      return {
        ...state,
        editingGroup: false,
        duplicateGroupError: true,
      };

    case DASHBOARD.CREATE_GROUP_MODEL:
      return {
        ...state,
        createIsOpen: !state.createIsOpen,
      };
    case DASHBOARD.DELETE_GROUP_MODEL:
      return {
        ...state,
        deleteIsOpen: !state.deleteIsOpen,
      };
    case DASHBOARD.API_USER_MODEL:
      return {
        ...state,
        apiModelIsOpen: !state.apiModelIsOpen,
      };
    case DASHBOARD.SEARCH_GROUP:
      let data = state.tempGroupState.filter((val) =>
        val.name.toLowerCase().includes(payload.search.toLowerCase())
      );
      return {
        ...state,
        groupStats: data,
        groupIncrement: state.groupIncrement + 1,
      };

    case DASHBOARD.SEARCH_INTERNAL_GROUP:
      let filterdata = state.tempGroupInternalState.filter((val) =>
        val.name.toLowerCase().includes(payload.search.toLowerCase())
      );
      return {
        ...state,
        groupInternalStats: filterdata,
        groupIncrement: state.groupIncrement + 1,
      };

    // Search API
    case DASHBOARD.SEARCH_API:
      let searchData = state.tempApiState.filter((val) =>
        val.name.toLowerCase().includes(payload.val.toLowerCase())
      );
      return {
        ...state,
        apiStats: searchData,
        apiIncrement: state.apiIncrement + 1,
      };

    // Search Internal API
    case DASHBOARD.SEARCH_INTERNAL_API:
      let searchApiData = state.tempInternalApiState.filter((val) =>
        val.name.toLowerCase().includes(payload.val.toLowerCase())
      );
      return {
        ...state,
        internalApiStats: searchApiData,
        apiIncrement: state.apiIncrement + 1,
      };
    case DASHBOARD.GET_GROUP_STATS:
      return {
        ...state,
        groupStats: payload,
        tempGroupState: payload,
      };
    case DASHBOARD.GET_INTERNAL_GROUP_STATS:
      return {
        ...state,
        groupInternalStats: payload,
        tempGroupInternalState: payload,
      };

    case DASHBOARD.GET_API_STATS:
      return {
        ...state,
        apiStats: payload,
        tempApiState: payload,
      };

    case DASHBOARD.GET_INTERNAL_API_STATS:
      return {
        ...state,
        internalApiStats: payload,
        tempInternalApiState: payload,
      };
    case DASHBOARD.RESET_GROUP_ERROR:
      return {
        ...state,
        duplicateGroupError: false,
      };
    default:
      return {
        ...state,
      };
  }
};
