import axios from "axios";
import { toast } from "react-toastify";
import { SERVER_ERROR, AUTH } from "./types";
import setAuthToken from "../../utils/setAuthToken";
//Login
export const login = (values) => async (dispatch) => {
  dispatch({ type: AUTH.LOGIN_LOADING });
  try {
    const response = await axios.post(`/api-token/`, values);
    dispatch({ type: AUTH.LOGIN_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: AUTH.LOGIN_FAIL });
    if (error && error.response) {
      const { data } = await error.response;
      if (data && data.errors) {
        toast.error(...data.errors[0]);
      } else {
        const message =
          data && data.non_field_errors.length
            ? data.non_field_errors[0]
            : SERVER_ERROR;
        toast.error(message);
      }
    }
  }
};
//Logout
export const logout = () => async (dispatch) => {
  dispatch({ type: AUTH.LOGOUT });
};

//Authorisation
export const checkAuthorisation = () => async (dispatch) => {
  dispatch({ type: AUTH.AUTHORISATION_LOADING });
  setAuthToken();
  try {
    const response = await axios.get(`/api/lg/lead-gen-permission/`);
    dispatch({ type: AUTH.AUTHORISATION_SUCCESS, payload: response.data });
    toast.success("You have logged in!");
  } catch (error) {
    dispatch({ type: AUTH.AUTHORISATION_FAIL });
    toast.error("You are not authorised to use this app");
  }
};
