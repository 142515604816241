import axios from 'axios';
import { toast } from 'react-toastify';
import { SERVER_ERROR, UPLOAD } from './types';
import setAuthToken from '../../utils/setAuthToken';
//Login
export const uploadFile = (values,history) => async (dispatch) => {
  try {
    dispatch({
        type: UPLOAD.SET_PROPS,
        payload: { uploadingFile: true },
      });
    setAuthToken();
    const response = await axios.post(`/api/lg/linkedin-user-emails/`, values);
    dispatch({ type: UPLOAD.UPLOAD_SUCCESS, payload: response.data });
  } catch (error) {
      if(error.response.status === 401){
        history.push("/app/auth");
        localStorage.removeItem('refresh');
        localStorage.removeItem('userToken');
      }else{
    dispatch({ type: UPLOAD.UPLOAD_FAIL });
  
      const message = error?.response?.data?.message;
      toast.error(message?message:SERVER_ERROR);
  }
}
};
export const fileDownloaded = () => async (dispatch) => {

    dispatch({
        type: UPLOAD.RESET_PROPS,
      });
   
};
